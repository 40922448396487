import React, { useEffect, useState } from "react";
import './ValentinePage.scss';
import ValentinePageDisplay from './ValentinePage.styled';
import ReactPlayer from "react-player";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import Modal from 'react-awesome-modal';
import { client } from "../../auth/Client";





import { Trans, withTranslation } from "react-i18next";


function ValentinePage({ t, props }) {
	const [text, setText] = useState("");
	const [visablemodalvalntine, setvisablemodalvalntine] = useState(false);

	

	var currentLang = localStorage.getItem("i18nextLng");
	const history = useHistory();

	useEffect(() => {
		checkAuth()
		
	  })

	  async function checkAuth() {
		console.log('checkAuth')
		const authorized = await client.auth();
		if (!authorized) {
			history.push('/login')
		}
		
	  }

	function handleChange(value) {
		console.log(value);
		setText(value);
	}

	async function sendComment(e) {
		e.preventDefault();
		console.log("send");
		const params = {
			msisdn: localStorage.getItem('phoneNum'),
			userId: localStorage.getItem('userId'),
			text: text

		};

		try {
			var apiUrl = "http://api.playit.mobi/api/v2/playit/pastime";
			const res = await axios.post(apiUrl, params);
			console.log("res", res)
			if (res.status) {
				setvisablemodalvalntine(true)
				setTimeout(() => {
					history.push("/");				
				}, 3000);

			}
			

		} catch (err) {
			console.log(err)

		}
	}

	return (
		<ValentinePageDisplay>
			<div className="operatorSignup">
				<div className="formsLayout">
					{/* <div className="header">
								<div className="logo" ></div>
						</div> */}
					<div className="links-Section-valntine">
						<div className="headetvalntine">
							<div className="headerValntineLogo" style={{ backgroundImage: currentLang == 'en' ? "url('/assets/images/logo1.png')" : "url('/assets/images/logo2.png')" }}>
							</div>
							<p><Trans>valntine-message</Trans></p>
						</div>

						<div className="comments">
							<form>
								<div className="inputs input-valntine">
									<textarea
										className="inputs"
										placeholder={t('commentPlacholder')}
										onChange={e => handleChange(e.target.value)}
									>
									</textarea>
									
								</div>
							</form>
						</div>
						<div style={{ marginTop: '-7vh' }}>

							<button
								type="button"
								className="valntinesend"
								onClick={e => { sendComment(e) }}
								disabled={text ? '' : 'disabled'}
							>
								<Trans>Send</Trans>
							</button>
						</div>
				
						<div className="footer">
                <div className="ncellTos ncellTos-valntine">
                  {/* <h3><Trans>valntine-terms</Trans></h3> */}

                  <p><Trans>valntine-terms</Trans></p>
                
                </div>
              </div>
				
					</div>


			
				</div>
			</div>


			<Modal
            visible={visablemodalvalntine}
            width="350"
            height="45"
            effect="fadeInUp"
            padding="0"
            //onClickAway={() => closeModalSubscribe()}
          >
            <div className="valntinemodal">
				
				<p><Trans>commentSend</Trans></p>
				
            </div>


          </Modal>
		</ValentinePageDisplay>
	)
}

export default withTranslation()(ValentinePage)
