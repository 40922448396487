import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../../helpers/bgSliders";
import "./SignupPage.styled";
import KorekiqSignupPageDisplay from "./SignupPage.styled";
import { setLoginData } from "../../../helpers/helpers";
import { Trans, withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { setAgencyData, saveTracker } from "../../../helpers/agencyTracker";



const randomBg = bgSliders.getRandomBg();
function korekTrendyPinPage({ t }) {
  const [activeData, setActiveData] = useState("");
  const [checkPhone, setCheckPhone] = useState(false);

  const [showverif, setShowverif] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");
  const [errorMesg, setErrorMesg] = useState(false);
  const [subMesg, setSubMesg] = useState(false);

  const [verfiErrorMesg, setVerfiErrorMesg] = useState(false);
  const [errorresverify, seterrorresverify] = useState("");

  const [randomString, setRandomString] = useState('');
  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");

  const [otpid, setotpid] = useState("");
  const [showLoading, setshowLoading] = useState(false);



  const history = useHistory();
  const [alert, setAlert] = useState(false);

  const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

 

  useEffect(() => {


    const urlsearch = window.location.search;
    if (urlsearch !== "") {
    
      setAgencyData(urlsearch);
    }
    const newRandomString = generateRandomString(10); 
      setRandomString(newRandomString);
      const fetchData = async () => {
        try {
          const res = await axios.get(`http://www.api.playit.mobi/api/v2/korek-trendy/get-script?ti=${newRandomString}&te=%23signup&isHeader=no`);
         
           if (res.data.status == true) {
             
             const addScript = document.createElement('script');
            
             addScript.innerHTML = res.data.script;
             document.head.appendChild(addScript);
             setshowLoading(false);
             var event = new Event('DCBProtectRun');
             document.dispatchEvent(event);
             localStorage.setItem("ts",res.data.ts);
            
           }
           else {
             setErrorMesg(true);
             setErrorMesgSubscribe(res.data.message)
             
           }
    
           setshowLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
     
      fetchData();
  }, []);


  const Subscribe = async e => {
    e.preventDefault();
    if (activeData) {
      var phon_n = activeData.toString();
      var url = "";
      let ts = localStorage.getItem("ts");
      url = `http://www.api.playit.mobi/api/v2/korek-trendy/send/pin?msisdn=${phon_n}&ts=${ts}`
      setshowLoading(true);
      axios.get(url)
        .then(res => {
          if (res.data.status === true) {
            if(res.data.subscribed== true){
             setSubMesg(true);
             setErrorMesgSubscribe(res.data.msg);

              setTimeout(() => {
                history.push("/login");
            }, 3000);

            }else{
              setShowverif(true)

            }
           /*  if (res.data.user_id) {
              setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
            }
            if (res.data.otpid) {
              setotpid(res.data.otpid)
            } */
          }
          else {
            if (res.data.msg != null) {
              setErrorMesgSubscribe(res.data.msg);
              setErrorMesg(true);


            }
            else {
              setErrorMesgSubscribe(t("register.SUBSCRIBE_ERROR"));
              setErrorMesg(true);


            }
             //setShowverif(true)

             //setshowLoading(false);
          }
          setshowLoading(false);
          //setShowverif(true)
        })
        .catch(function (error) {
          setErrorMesg(true)
          setShowverif(false)
        })
        .finally(function () {
         //console.log('complete');
        });


    }
  };


  const verify = async e => {
    e.preventDefault();
    if (verifyCode) {
      var phon_n = activeData;
      setshowLoading(true);
      let ts = localStorage.getItem("ts");
      axios.get(`http://www.api.playit.mobi/api/v2/korek-trendy/verify/pin?msisdn=${phon_n}&pin=${verifyCode}&ti=${randomString}&ts=${ts}`)
        .then(res => {
          if (res.data.status == true) {
            tracker(phon_n);
            gtag_report_conversion();
            setVerfiErrorMesg(false);
            //tracker(phon_n);
            if (res.data.user_id) {
              
              setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
              // window.location.href = "/";
            }
           //  window.location.href = "/";
           history.push("/catch-zainiqgamezroid")
          } else {
            if (res.data.msg != null) {
              seterrorresverify(res.data.msg);;
              setVerfiErrorMesg(true);

            }
            else {
              // setErrorMesgSubscribe(t("register.SUBSCRIBE_ERROR"));
              seterrorresverify(t("register.VERIFY_ERROR"));
              setVerfiErrorMesg(true);


            }
             setVerfiErrorMesg(true);
             seterrorresverify(res.data.msg);
          }
          setshowLoading(false);

        })
        .catch(function (error) {
         //console.log(error);
          // setVerfiErrorMesg(true);
          // seterrorresverify(res.data.msg);

        })
        .finally(function () {

         //console.log('complete');
        });
    }
  };


  function gtag_report_conversion() {
    let tracker_value = localStorage.getItem('tracker');
      if(localStorage.getItem(tracker_value) != '1'){
        window.gtag('event', 'conversion', {
          'send_to': 'AW-619421651/C_ScCIHe_qcZENO_rqcC'
      });
      localStorage.setItem(tracker_value,'1')
    }
    return false;
  }


  const tracker = async (GMSISDN) => {
    var datag = {};
    if (localStorage.getItem('tracker')) {
     
      datag = {
        key: 'parameter',
        company: localStorage.getItem("trackerCompany"),
        value: localStorage.getItem('tracker'),
        identifierKey: 'id',
        identifierValue: 'EbMD6FMZhU',
        phoneNum: GMSISDN
      };
     //console.log(datag)

    }
    await saveTracker(datag);

  }
  return (
    <KorekiqSignupPageDisplay className="">
    {alert && (
      <FloatingAlert type={"danger"}>
        In order to signup please turn your mobile data.
      </FloatingAlert>
    )}

    {errorMesg ? (
      <FloatingAlert type={"error"}>
        <Trans>{errorMesgSubscribe}</Trans>
      </FloatingAlert>
    ) : null}
     {subMesg ? (
      <FloatingAlert type={"success"}>
        <Trans>{errorMesgSubscribe}</Trans>
      </FloatingAlert>
    ) : null}

    {verfiErrorMesg ? (
      <FloatingAlert type={"error"}>
        <Trans>{errorresverify}</Trans>
      </FloatingAlert>
    ) : null}

    <div className='operatorSignup'>
      <div className="formsLayout formsLayoutjawal">

        <div className='header header_gamezone'>
          <Link to="/">
            <div className='logo logo gamezoneLogo' />
          </Link>
        </div>
        {!showverif ?
          <div className="links-Section links-Sectionjawwalgamezorid">

            <h3 style={{ paddingBottom: '1px' }} className="gamezoridcontent" id="gamezoridcon">
              <Trans>Your content is ready!</Trans>
            </h3>
            <div className="play"></div>
            <div className="number-inputs">
               <input readOnly value={+964} className="countryCode"/>
               <input
              type="text"
              className="input gamezoneInput"   
              style={{fontSize: '12px'}}
              placeholder={t("Enter_your_phone_number")}
              value={activeData}
              disabled={checkPhone == true}
              onChange={(e) => setActiveData(e.target.value.replace(/[^0-9]/g, ""))}

            />

              </div>


              <button
              id="sub"
              className="btn"
              disabled={activeData ? "" : "disabled"}
              onClick={(e) => { Subscribe(e) }}
            >
              {t("Subscribe")}
            </button> 
             
             
            
           

            <div className="row text-center" >
              <div className="col-xl-4 col-lg-4 col-4 px-5">
                <div className="games-info jawwalgames-info">
                  <span>98%</span><img src="/assets/images/subscribegamezriod/like.png" alt="img" className="iconsimg" />
                  <p><Trans>evaluation</Trans></p>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-4 px-5">
                <div className="games-info jawwalgames-info">
                  <span>1000+</span><img src="/assets/images/subscribegamezriod/Repeat.png" alt="img" className="iconsimg" />
                  <p><Trans>games</Trans></p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-4 px-5">
                <div className="games-info jawwalgames-info">
                  <span>500k+</span><img src="/assets/images/subscribegamezriod/group.png" alt="img" className="iconsimg" />
                  <p><Trans>users</Trans></p>
                </div>
              </div>
            </div>


            <div className="footer jawwalgamezoridFooter zainiqFooter">
              {!showverif ? <div>

              </div> : null}
              <div className="ncellTos jawwalgamezoridncellTos">
                <h3><Trans>terms_and_conditions</Trans></h3>
                <p  className="text-center"><Trans>korik-tendy-condtion</Trans></p>
                
              </div>
            </div>
          </div>

          : null}

        {showverif ?
          <div className="verfiySection">
            <p><Trans>verifycodeJawwalGameszorid</Trans></p>
            <input
              type="text"
              className="input"
              value={verifyCode}
              placeholder={t("Enter_verifycode")}
              onChange={(e) => setVerifyCode(e.target.value.replace(/[^0-9]/g, ""))}

            />
            <br></br>
            <br></br> <br></br>
            <button
              id="signup"
              className="btn"
              disabled={verifyCode ? "" : "disabled"}
              onClick={(e) => {
                verify(e);
              }} >
              {t("Verify")}
            </button>

          </div> : null
        }

        {showLoading ?
          <div className="load">
            <div className="spinerC">
              <div className="spinner"></div>
            </div>
          </div>
          : null}




      </div>
    </div>

   

  </KorekiqSignupPageDisplay>
  );
}
export default withTranslation()(korekTrendyPinPage)
