import React, { useEffect, useState } from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { client } from "../../auth/Client";
import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../helpers/bgSliders";
import "./BahrainLoginPage.scss";
import axios from "axios";
import BahrainLoginPageDisplay from "./BahrainLoginPage.styled";
import { useHistory } from 'react-router-dom';
import { setLoginData } from "../../helpers/helpers";

import queryString from "query-string";

const randomBg = bgSliders.getRandomBg();
function BahrainLoginPage({ t }) {


  const [operator, setOperator] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [errsubMsg, seterrsubMsg] = useState("");
  const [errors, seterrors] = useState(false);
  const [errsub, seterrsub] = useState(false);
  const [show, setshow] = useState(true);
  const [loading, setloading] = useState(false);
  const [validcode, setvalidcode] = useState(false);

  const history = useHistory();



  useEffect(() => {
    const query = queryString.parse(window.location.search);
    const hostName = window.location.hostname;

    if (query.code && query.operator) {
      setvalidcode(true);
      login(query.code, query.operator)

    }
    else {
      hostName.includes("zainbh") ? setOperator('zain') : setOperator('stc');
    }


  })



  async function check() {
    if (await checkAuth()) {
      history.push("/");
    }
  }


  async function login(code, operator) {
    if (operator && code) {
      var url = `http://www.api.playit.mobi/api/v2/bahrain/login?operator=${operator}&code=${code}`;
      //  setloading(true);
      axios.get(url)
        .then(res => {
          ////console.log(res.data)
          if (res.data.status == true) {
            if (res.data.data.username && res.data.data.password) {
              const hasAccess = confirmLogin(res.data.data.username, res.data.data.password)
              if (hasAccess) {
                setLoginData(res.data.data.user_id, res.data.data.token, res.data.data.username, res.data.data.photo, res.data.data.operator_id, res.data.data.user_email, res.data.data.msisdn);
                history.push(`/slogin/${res.data.data.user_id}`);
                // history.push("/");
              }
            }
          }
          else {
            seterrors(false)
            seterrsub(true)
            seterrsubMsg(res.data.data);
          }
          // setloading(false)

        })
        .catch(function (error) {
         //console.log(error)
        })
        .finally(function () {
         //console.log('complete');
        });


    }

  }



  async function redirectUrl(e, operator) {
    ////console.log("task")
    e.preventDefault();
    if (operator) {
      var url = `http://www.api.playit.mobi/api/v2/bahrain/loginfnprepare?operator=${operator}`;
      axios.get(url)
        .then(res => {
          if (res.data.status == true) {
            window.location.href = res.data.url
          }
          else {
            seterrors(false)
            seterrsub(true)
            seterrsubMsg(res.data.data);
          }

        })
        .catch(function (error) {
         //console.log(error)
        })
        .finally(function () {
         //console.log('complete');
        });


    }
  }

  async function checkAuth() {

    const auth = await client.auth();
    return auth;
  }

  async function confirmLogin(username, password) {

    const hasAccess = await client.login(username, password);
    return hasAccess;
  }




  return (
    <>
      {loading ?
        <div>
          <div className="spinerC">
            <div className="spinner"></div>
          </div>
        </div>
        : ""}
      <BahrainLoginPageDisplay className="" bgImg={randomBg}>

        <div className="formPage">
          <form
          // onSubmit={e => {
          //   login(e);
          // }}
          >
            <div className="formsLayout" style={{ minHeight: '45vh' }}>
              <div className="header">
                <Link to="/">
                  <div className="logo" />
                </Link>
              </div>
              <div className="smsText" style={show ? { display: 'none' } : {}}>
                <p >يجب عليك الدخول عن طريق الرابط الموجود في SMS </p>
              </div>


            </div>
          </form>


          <div className="footer">
            <div className="normBtn">
              <button className="btn" onClick={e => redirectUrl(e, operator)} disabled={validcode ? "disabled": ""}>
                <Trans>sign_in</Trans>
              </button>
            </div>



            <p>
              <Trans>login.don’t_have_account</Trans>{" "}
              <Link to="signup">
                <Trans>sign_up</Trans>
              </Link>
            </p>
          </div>
          {errors ? <FloatingAlert type="error"><Trans>{errorMsg}</Trans></FloatingAlert> : ""}
          {errsub ? <FloatingAlert type="error"><Trans>{errsubMsg}</Trans></FloatingAlert> : ""}


        </div>
      </BahrainLoginPageDisplay>
    </>
  );

}




/* End Update State Use REDUX */
export default withTranslation()(BahrainLoginPage)



