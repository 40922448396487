import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../helpers/bgSliders";
//import "./SignupPage.styled";
import "./MondiaCatchPage.scss"
import MondiaCatchPageDisplay from "./MondiaCatchPage.styled";
import { setLoginData } from "../../helpers/helpers";
import { Trans, withTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const randomBg = bgSliders.getRandomBg();
function MondiaCatchPage({ t }) {
  const [errorMesg, setErrorMesg] = useState(false);
  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");
  const location = useLocation();
  const history = useHistory();


  useEffect(() => {
    const params = queryString.parse(window.location.search);
   //console.log(window.location.search);
    if (params.status === "ERROR" && params.errorCode == '4001') {
      history.push("/");
    }
    else if (params.status === "SUCCESS") {
      //let customerId = params.customerId;
      var url2 = "";
      url2 = `http://www.api.playit.mobi/api/v1/getToken/${params.customerId}/1`;
      axios.get(url2)
        .then(res => {
         //console.log(res);
          if (res.data.code == 200) {
            //make login
            history.push("/");
          }
          else {
            localStorage.setItem('mondiaCustomerId', params.customerId);
            history.push("/");

          }
        })
        .catch(function (error) {
          setErrorMesg(true)
        })
        .finally(function () {
         //console.log('complete');
        });


    }
    else if (params.redirect === 'mondia') {
      subscribe();
    }

  }, []);

  function subscribe() {

    location.href = `http://login.mondiamediamena.com/billinggw-lcm/billing?merchantId=197&operatorId=22&redirect=http://dzo.playit.mobi/mondiaverify/W&method=subscribe&imgPath=http%3A%2F%2Fapi.playit.mobi%2Fgames-photos%2Fmondia.png&productCd=PLAYITALGERIA&subPackage=W`;
  }




  return (
    <MondiaCatchPageDisplay>

      {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorMesgSubscribe}</Trans>
        </FloatingAlert>
      ) : null}



      <div className="container-loadaing">
        <div className="loadingLayout">
          <div className="keep-waiting">
            <div>
              <div className="sk-chase">

                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
              </div>
            </div>
            {/* <div style={{ marginTop: '80px' }}>
              <h1>برجاء الانتظار</h1>
              <p>سيتم تحويلك الي الصفحه خلال لحظات</p>
            </div> */}
          </div>


        </div>

      </div>

    </MondiaCatchPageDisplay>
  );
}
export default withTranslation()(MondiaCatchPage)
