import React, { Component } from "react";
import img from "../../assets/images/complete.svg";
import bgSliders from "../../helpers/bgSliders";
import h from "../../helpers/helpers";
import "./SubscribeCompletePage.scss";
import SubscribeCompletePageDisplay from "./SubscribeCompletePage.styled";
import { Link } from 'react-router-dom';
import { saveTracker } from "../../helpers/agencyTracker";
import queryString from "query-string";

const randomBg = bgSliders.getRandomBg();

// Function to report conversion
function gtag_report_conversion() {
  let tracker_value = localStorage.getItem('tracker');
  if (localStorage.getItem(tracker_value) !== '1') {
    window.gtag('event', 'conversion', {
      'send_to': 'AW-619421651/C_ScCIHe_qcZENO_rqcC'
    });
    localStorage.setItem(tracker_value, '1');
  }
  return false;
}

// Tracker function
const tracker = async (GMSISDN) => {
  var datag = {};
  if (localStorage.getItem('tracker')) {
    datag = {
      key: 'parameter',
      company: localStorage.getItem("trackerCompany"),
      value: localStorage.getItem('tracker'),
      identifierKey: 'id',
      identifierValue: 'EbMD6FMZhU',
      phoneNum: GMSISDN
    };
  }
  await saveTracker(datag);
};

export default class SubscribeCompletePage extends Component {
  async componentDidMount() {
    
    const data = queryString.parse(window.location.search);
    
      
    if (data.msisdn) {
      await tracker(data.msisdn);
    }
    gtag_report_conversion();

    await h.wait(5000);
    this.props.history.push("/");
  }

  render() {
    return (
      <SubscribeCompletePageDisplay bgImg={randomBg}>
        <div className="formPage">
          <div className="formsLayout">
            <div className="header">
              <Link to="/">
                <div className="logo" />
              </Link>
            </div>
            <div className="center">
              <img src={img} alt="Complete" />
            </div>
            <div className="footer">
              <div>
                <p>subscription complete</p>
              </div>{" "}
              <div>If you're not redirected within 5 seconds </div>
              <div>
                <a href="/">Click Here</a>
              </div>
            </div>
          </div>
        </div>
      </SubscribeCompletePageDisplay>
    );
  }
}
