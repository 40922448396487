import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../helpers/bgSliders";
//import "./SignupPage.styled";
import "./IndonesiaVerifyPage.scss";

import IndonesiaVerifyPageDisplay from "./IndonesiaVerifyPage.styled";
import { setLoginData } from "../../helpers/helpers";
import { Trans, withTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { saveTracker } from "../../helpers/agencyTracker";

import { client } from "../../auth/Client";




const randomBg = bgSliders.randomBgooredoo();
function IndonesiaVerifyPage({ t }) {
  const [isErrorExist, setisErrorExist] = useState(false);
  const [errorMesg, setErrorMesg] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [showLoading, setshowLoading] = useState(false);
  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");
  const location = useLocation();
  const history = useHistory();

 
    async function login(params) {
      if (params.reason_code) {

        var url = `http://www.api.playit.mobi/api/v2/dot/redirectResult?
        charge_status=${params.charge_status}&
        dot_txid=${params.dot_txid}&
        msisdn=${params.msisdn}&
        op_id=${params.op_id}&
        partner_txid=${params.partner_txid}&
        reason_codes=${params.reason_code}&
        reason_desc=${params.reason_desc}&
        service_id=${params.service_id}&
        signature=${params.signature}&
        sub_status=${params.sub_status}`

        axios.get(url)
          .then(res => {
          //console.log('res after sub' , res.data)
            if (res.data.status == true) {
              if (res.data.data.username && res.data.data.password) {
                const hasAccess = confirmLogin(res.data.data.username ,res.data.data.password);
                if (hasAccess) { 
                   //console.log('login access done')
                    setLoginData(res.data.data.user_id, res.data.data.token, res.data.data.username, res.data.data.photo, res.data.data.operator_id, res.data.data.user_email, res.data.data.msisdn);
                  history.push(`/slogin/${res.data.data.user_id}`);
               
                  
                }
              }
            }
            else {
              seterrorMessage(res.data.data)
              setErrorMesgSubscribe(res.data.data)
              setisErrorExist(true);
              setErrorMesg(true);
            
           
            }
  
          })
          .catch(function (error) {
           //console.log(error)
          })
          .finally(function () {
           //console.log('complete');
          });
  
  
      }
      
    }

    async function confirmLogin(username,password) {

      const hasAccess = await client.login(username,password);
      return hasAccess;
    }
  

    
  useEffect(() => {
    const params = queryString.parse(window.location.search);
 
    var queryParams;
   //console.log('params',params);
    if (params.sub_status === "1") {
     //console.log("SUCCESS")
      queryParams = window.location.search.split('?');
      queryParams = queryParams[1];
     //console.log('queryParams' ,queryParams);
      setMessage(params.reason_desc)
      login(params);
    }
    else if (params.sub_status === "0") {
      if(params.reason_desc === 'USER_ALREADY_SUBSCRIBED'){
       //console.log("ERROR")
        setisErrorExist(true);
        seterrorMessage(params.reason_desc);
               //console.log(params.reason_desc);
               //console.log(errorMesg);  
                login(params);

      }else{
       //console.log("ERROR")
        setisErrorExist(true);
        seterrorMessage(params.reason_desc);
               //console.log(params.reason_desc);
               //console.log(errorMesg);
   //             login(params.code,params.operator,params.PackageCode,false);
   queryParams = window.location.search.split('?');
   queryParams = queryParams[1];
  //console.log('queryParams' ,queryParams);
   //queryParams += "&PackageCode=" + packageCode;
   login(params);

 


  
      }
            


    }
   
    else {
      queryParams = window.location.pathname + window.location.search;
      queryParams = queryParams.replace('/Indonesiaverify', '');

     //console.log("URL",queryParams );

      setisErrorExist(true);
     //console.log(params.reason_desc)
      seterrorMessage(params.reason_desc);
    
    }

  }, []);
  
  


  return (
    <IndonesiaVerifyPageDisplay  bgImg={randomBg}>

      {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorMesgSubscribe}</Trans>
        </FloatingAlert>
      ) : null}

  

      <div className='operatorSignup'>
        <div className="formsLayout formsLayoutjawal formsLayoutduverify">
      

          <div className="Permission">
            {isErrorExist ?     
            <p>{errorMessage}</p>
            : 
            <p>{message}</p>
            }
          </div>

       

          {showLoading ?
            <div className="load">
              <div className="spinerC">
                <div className="spinner"></div>
              </div>
            </div>
            : null}




        </div>
      </div>

    </IndonesiaVerifyPageDisplay>
  );
}
export default withTranslation()(IndonesiaVerifyPage)
