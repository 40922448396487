import React, { useEffect, useState } from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { client } from "../../auth/Client";
import img from "../../assets/images/complete.svg";

import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../helpers/bgSliders";
import "./OmantelSuccessPage.scss";
import Modal from 'react-awesome-modal';
import axios from "axios";
import { setLoginData } from "../../helpers/helpers";
import OmantelSuccessPageDisplay from "./OmantelSuccessPage.styled";
import { useHistory } from 'react-router-dom';

import queryString from "query-string";
  const query = queryString.parse(window.location.search);

const randomBg = bgSliders.getRandomBg();
function OmantelSuccessPage({ t }) {


  const [phone, setphone] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [errsubMsg, seterrsubMsg] = useState("");
  const [errors, seterrors] = useState(false);
  const [errsub, seterrsub] = useState(false);
  const [verify_errors, setverify_errors] = useState(false);
  const [show, setshow] = useState(true);
  const [loading, setloading] = useState(false);
  const [validcode, setvalidcode] = useState(false);

  const [phonePlaceholder, setphonePlaceholder] = useState("enter_phone_number");
  const history = useHistory();



  useEffect(() => {
 
  } , [])





  async function login() {
    
      var url = `http://www.api.playit.mobi/api/v2/mondia-oman/get-sub-details?operator=ooredoo&plan_id=${query.plan_id}&code=${query.code}`;
      axios.post(url , {})
        .then(res => {
          if (res.data.status == "success") {
            if (res.data.data.username && res.data.data.password) {
            

              const hasAccess = confirmLogin(res.data.data.username ,res.data.data.password);
              if (hasAccess) { 
                
                  setLoginData(res.data.data.user_id, res.data.data.token, res.data.data.username, res.data.data.photo, res.data.data.operator_id, res.data.data.user_email, res.data.data.msisdn);
                 
                history.push(`/`);
               
                
              }
            }
          }
          else {
            seterrorMsg(res.data.data)
            seterrors(true);
           
          }
          setloading(false)

        })
        .catch(function (error) {
         console.log(error)
        })
        .finally(function () {
         //console.log('complete');
        });


    
    
  }

  
 
  async function checkAuth() {

    const auth = await client.auth();
    return auth;
  }

 


  async function confirmLogin(username,password) {

    const hasAccess = await client.login(username,password);
    return hasAccess;
  }




 
  return (
    <>
      {loading ?
        <div>
          <div className="spinerC">
            <div className="spinner"></div>
          </div>
        </div>
        : ""}
      <OmantelSuccessPageDisplay className="" bgImg={randomBg}>

        <div className="formPage">
          <form
         
          >
            <div className="formsLayout" style={{ minHeight: '45vh' }}>
              <div className="header">
                <Link to="/">
                  <div className="logo" />
                </Link>
              </div>
         
              <div className="center">
              <img src={img} alt="Complete" />
            </div>

            </div>
          </form>


          <div className="footer">
            <div className="normBtn">
              <button className="btn" onClick={e => login()} >
                <Trans>sign_in</Trans>
              </button>
            </div>



            <p>
              <Trans>login.don’t_have_account</Trans>{" "}
              <Link to="signup">
                <Trans>sign_up</Trans>
              </Link>
            </p>
          </div>
          {errors ? <FloatingAlert type="error"><Trans>{errorMsg}</Trans></FloatingAlert> : ""}
          {errsub ? <FloatingAlert type="error"><Trans>{errsubMsg}</Trans></FloatingAlert> : ""}


        </div>
      </OmantelSuccessPageDisplay>
    </>
  );

}




/* End Update State Use REDUX */
export default withTranslation()(OmantelSuccessPage)



