import React, { Component, Suspense } from "react";
import "./App.scss";
import axios from "axios";
import h from "./helpers/helpers";   
import { ethLogin } from "./operators/pages/EthSignupPage/services";
import { ncellLogin } from "./operators/pages/NcellSignupPage/services";
import { bhrainLogin } from "./operators/pages/VivaSignupPage/services";
import Routes from "./Routes";
import changeTheme from "./theming/themes";
import queryString from "query-string";
//import * as localLang from "./i18n/i18n";

class App extends Component {
  async componentDidMount() {
    //const tdzo = window.location.hostname.includes("tdzo");
    let url = window.location.href;
    if (url.includes("signup") && url.includes("dzo") && url.includes("?")){
      localStorage.setItem("i18nextLng", "fr");
    }
 /*  if(url.includes("libya")){
    document.body.className = 'cptpl_page';
    const hiddenInput = document.createElement('input');
      hiddenInput.type = 'hidden';
      hiddenInput.value = '20230925';
      hiddenInput.name = 'cptpl_template';
      hiddenInput.className = 'test cptpl_template';
      document.body.prepend(hiddenInput);

  } */
    //yousry lang
    const params = queryString.parse(window.location.search);
    if (params.lang) {
     //console.log("lang");
      localStorage.setItem("i18nextLng", params.lang);
      //localLang.checklocalLanguage();
    }
    rtlSetting();
    await setTheme();
    await autoLogin();
    const url_ais = window.location.hostname.includes("ais.playit.mobi");
    if(url_ais){
      await checkloginTaihland();
    }
  }
  

  render() {
    return (
      <div className="App">
        <Suspense
          fallback={
            <div>
              <div className="spinerC">
                <div className="spinner"></div>
              </div>
            </div>
          }
        >
          <Routes />
        </Suspense>
      </div>
    );
  }
}

// function clearBrowserCache() { const contextValue = useClearBrowserCache(); return null; }
async function setTheme() {
  //////////// check hostname
  const hostName = window.location.hostname;
  //const hostName = "aciq.gamezworld.mobi"; 
  let theme = h.getThemeByHostName(hostName);
  console.log(theme);
  const countryCode = await h.getCountryCode();
  if (theme) {
   //console.log('11111111');
    return changeTheme(theme);
  }
  theme = h.getThemeByCountryCode(countryCode);
  if (theme) { 
   //console.log('2222222');
    return changeTheme(theme);
  }
 //console.log('3333333333');
  return changeTheme("default");
}
function rtlSetting() {
  if (localStorage.getItem("i18nextLng")) {
    const lang = localStorage.getItem("i18nextLng");

    //document.body.dir = lang === "en" ? "ltr" : "rtl";
    if (lang === "en" || lang === "fr" || lang === "pt" || lang === "indo") {
      document.body.dir = "ltr";
    } else {
      document.body.dir = "rtl";
    }
  }
}
async function checkloginTaihland(){
  
  const urlsearch_ais = window.location.search;
  const data_ais = queryString.parse(urlsearch_ais);
        
  var url ='http://api.playit.mobi/api/v2/digitallabs-thailand/check-token?token='+data_ais.t;
  //setshowLoading(true);
  axios.get(url)
    .then(res => {
    //  console.log(res);
    //  console.log(res.data.status);
      if (res.data.status == true) {
        localStorage.setItem("userId", 'z8ERLZuu9p');     
         localStorage.setItem("token", 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE3MTkxMzgxMzEsImV4cCI6MTc1MDY3NDEzMSwic3ViIjoiVXNlciBMb2dpbiIsInVzZXIiOiJ6OEVSTFp1dTlwIiwic2Vzc2lvbiI6IjY2NzdmNzUzYzhmNGQifQ.2bZO8AJ5D86wv0HFVbDMadPX28RwFgjXY7FSj9CKaJACTUdzS7SuZfsB6_uvbRVgseDd1MSG1FXeMScufJTqFA');
         localStorage.setItem("userName", '422555080');
         localStorage.setItem("userPhoto", '');
         localStorage.setItem("operatorId", '');
         localStorage.setItem("email", '');
         localStorage.setItem("phoneNum", '422555080');
     
      }else {
         
      }

    })
    .catch(function (error) {
      // setErrorMesg(true)
    })
    .finally(function () {
     //console.log('complete');
    });
}

async function autoLogin() {
  const hostname = document.location.host;
  if (hostname === "ethio.playit.mobi") {
    const res = await ethLogin();
    if (res) {
      // window.location.reload();
    }
  } else if (hostname === "ncell.playit.mobi") {
    const res = await ncellLogin();
    if (res) {
    }
  } else if (hostname === "ncell.playit.mobi") {
    const res = await bhrainLogin();
    if (res) {
    }
  }
}
export default App;
