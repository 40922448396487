import React, { useEffect, useState } from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { client } from "../../auth/Client";
import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../helpers/bgSliders";
import "./EgyptLoginPage.scss";
import Modal from 'react-awesome-modal';
import axios from "axios";
import { setLoginData } from "../../helpers/helpers";
import EgyptLoginPageDisplay from "./EgyptLoginPage.styled";
import { useHistory } from 'react-router-dom';

import queryString from "query-string";

const randomBg = bgSliders.getRandomBg();
function EgyptLoginPage({ t }) {


  const [phone, setphone] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [errsubMsg, seterrsubMsg] = useState("");
  const [errors, seterrors] = useState(false);
  const [errsub, seterrsub] = useState(false);
  const [verify_errors, setverify_errors] = useState(false);
  const [show, setshow] = useState(true);
  const [loading, setloading] = useState(false);
  const [validcode, setvalidcode] = useState(false);

  const [phonePlaceholder, setphonePlaceholder] = useState("enter_phone_number");
  const history = useHistory();



  useEffect(() => {
//     if(window.location.hostname.includes('local'))
// {
  const query = queryString.parse(window.location.search);
  if(query.code)
  {
    setvalidcode(true)
    login(query.code)
    //localStorage.setItem('code' , query.code)
    ////console.log(query.code)
  //}



}
    //check();
  })



  async function check() {
    if (await checkAuth()) {
      history.push("/");
    }
  }


   async function login(code) {
   
    if (code) {
      var url = `http://www.api.playit.mobi/api/v2/du/fetchSubDetails?code=${code}`;
    //  setloading(true);
      axios.get(url)
        .then(res => {
         ////console.log(res.data)
          if (res.data.status == true) {
            if (res.data.data.username && res.data.data.password) {
              const hasAccess = confirmLogin(res.data.data.username ,res.data.data.password)
              if (hasAccess) {
                history.push("/");
                //window.location.reload();
              }
            }
          }
          else {
            seterrors(false)
            seterrsub(true)
            seterrsubMsg(res.data.data);
          }
         // setloading(false)

        })
        .catch(function (error) {
         //console.log(error)
        })
        .finally(function () {
         //console.log('complete');
        });


    }
    
  }

  async function Subscribe(e,operator) {
   ////console.log("task")
    e.preventDefault();
   // window.location.href="https://gateway.mondiapay.com/v1/api/oauth/authorize?response_type=code&client_id=b6a12c83-1656-4a5b-93c7-635f7aedd6de&redirect_uri=http://du.playit.mobi/login"
   var url = `http://api.playit.mobi/api/v2/egypt/loginfnprepare?operator=${operator}`;
   
   axios
     .get(url)
     .then((res) => {
      //console.log(res.data.status);
       if (res.data.status == true) {
         window.location.href = res.data.url;
         // window.location.href = 'http://login.mondiamediamena.com/billinggw-lcm/billing?merchantId=197&operatorId=22&redirect=http://dzo.playit.mobi/mondiaverify/W&method=subscribe&imgPath=http%3A%2F%2Fapi.playit.mobi%2Fgames-photos%2Fmondia.png&productCd=PLAYITALGERIA&subPackage=W';
       } 

     })
     .catch(function (error) {

     })
     .finally(function () {
      //console.log("complete");
     });
   
  }

  async function checkAuth() {

    const auth = await client.auth();
    return auth;
  }

  function isValid(phone) {
    return phone ? true : false;
  }


  async function confirmLogin(username,password) {

    const hasAccess = await client.login(username,password);
    return hasAccess;
  }


  function onInputChange(value) {
    setphone(value);

  }

  return (
    <>
      {loading ?
        <div>
          <div className="spinerC">
            <div className="spinner"></div>
          </div>
        </div>
        : ""}
      <EgyptLoginPageDisplay className="" bgImg={randomBg}>

        <div className="formPage">
          <form
          // onSubmit={e => {
          //   login(e);
          // }}
          >
            <div className="formsLayout" style={{ minHeight: '35vh' }}>
              <div className="header">
                <Link to="/">
                  <div className="logo" />
                </Link>
              </div>
              <div className="smsText" style={show ? { display: 'none' } : {}}>
                <p >يجب عليك الدخول عن طريق الرابط الموجود في SMS </p>
              </div>
              {/* <div className="center" style={{ width: '90%' }}>

                <div className="form">
                  <div className="iconInput">
                    <i className="icon phone" />
                    <input
                      type="number"
                      className="input"
                      autoComplete="phone"
                      placeholder={t("enter_phone_number")}
                      onChange={e =>
                        onInputChange(e.target.value)
                      }
                    />
                  </div>

                </div>
              </div> */}

            </div>
          </form>


          <div className="footer">
            <div className="normBtn">
            {t('Please select Your Operator')}
            <br/><br/>
            <div className="row text-center content-row">
                  <div className="col-xl-3 col-lg-3 col-3">
                    <div className="games-info egy-info Egy-info butoonRound">
                      <a
                        onClick={(e) => {
                          Subscribe(e, "vodafone");
                        }}
                      >
                        <img
                          src="/assets/images/Egypt/vod2.png"
                          alt="img"
                          className="iconsimg"
                        />
                      </a>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-3">
                    <div className="games-info egy-info Egy-info butoonRound">
                      <a
                        onClick={(e) => {
                          Subscribe(e, "etisalat");
                        }}
                      >
                        <img
                          src="/assets/images/Egypt/ets.png"
                          alt="img"
                          className="iconsimg"
                          style={{ width: "5.8em" }}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-3">
                    <div className="games-info egy-info egy-info3 Egy-info butoonRound">
                      <a
                        onClick={(e) => {
                          Subscribe(e, "orange");
                        }}
                      >
                        <img
                          src="/assets/images/Egypt/orange.png"
                          alt="img"
                          className="iconsimg"
                          style={{ width: "5.8em" }}
                        />
                      </a>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-3">
                    <div className="games-info egy-info egy-info3 Egy-info butoonRound">
                      <a href=""
                        onClick={(e) => {
                          Subscribe(e, "we");
                        }}
                      >
                        <img
                          src="/assets/images/Egypt/we.png"
                          alt="img"
                          className="iconsimg"
                          style={{ width: "2.4em" }}
                        />
                      </a>
                    </div>
                  </div>
                      </div>
              {/* <button className="btn" onClick={e => redirectUrl(e)} disabled={validcode ? "disabled" : ""}>
                <Trans>sign_in</Trans>
              </button> */}
            </div>



            <p>
              <Trans>login.don’t_have_account</Trans>{" "}
              <Link to="signup">
                <Trans>sign_up</Trans>
              </Link>
            </p>
          </div>
          {errors ? <FloatingAlert type="error"><Trans>{errorMsg}</Trans></FloatingAlert> : ""}
          {errsub ? <FloatingAlert type="error"><Trans>{errsubMsg}</Trans></FloatingAlert> : ""}


        </div>
      </EgyptLoginPageDisplay>
    </>
  );

}




/* End Update State Use REDUX */
export default withTranslation()(EgyptLoginPage)



