import React, { useEffect, useState } from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { client } from "../../auth/Client";
import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../helpers/bgSliders";
import "./AlgeriaorLoginPage.scss";
import Modal from 'react-awesome-modal';
import axios from "axios";
import { setLoginData } from "../../helpers/helpers";
import AlgeriaorLoginPageDisplay from "./AlgeriaorLoginPage.styled";
import { useHistory } from 'react-router-dom';

import queryString from "query-string";

const randomBg = bgSliders.getRandomBg();
function AlgeriaorLoginPage({ t }) {


  const [phone, setphone] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [errsubMsg, seterrsubMsg] = useState("");
  const [errors, seterrors] = useState(false);
  const [errsub, seterrsub] = useState(false);
  const [verify_errors, setverify_errors] = useState(false);
  const [show, setshow] = useState(true);
  const [loading, setloading] = useState(false);
  const [validcode, setvalidcode] = useState(false);

  const [phonePlaceholder, setphonePlaceholder] = useState("enter_phone_number");
  const history = useHistory();



  useEffect(() => {
//     if(window.location.hostname.includes('local'))
// {
  const query = queryString.parse(window.location.search);
  if(query.code)
  {
    setvalidcode(true)
    login(query.code)
    //localStorage.setItem('code' , query.code)
    ////console.log(query.code)
  //}



}
    //check();
  })



  async function check() {
    if (await checkAuth()) {
      history.push("/");
    }
  }


   async function login(code) {
   
    if (code) {
      var url = `http://www.api.playit.mobi/api/v1/mondia/fetchSubDetails?code=${code}`;
    //  setloading(true);
      axios.get(url)
        .then(res => {
         ////console.log(res.data)
          if (res.data.status == true) {
            if (res.data.data.username && res.data.data.password) {
              const hasAccess = confirmLogin(res.data.data.username ,res.data.data.password)
              if (hasAccess) {
                history.push("/");
              }
            }
          }
          else {
            seterrors(false)
            seterrsub(true)
            seterrsubMsg(res.data.data);
          }
         // setloading(false)

        })
        .catch(function (error) {
         //console.log(error)
        })
        .finally(function () {
         //console.log('complete');
        });


    }
    
  }

  // async function login(e) {
  //  //console.log("task")
  //   e.preventDefault();
  //   if (!isValid(phone)) {
  //     seterrors(true);
  //     seterrorMsg("Empty_Phone_Number")
  //     return;
  //   }
  //  //console.log(phone)
  //   if (phone) {
  //     var url = `http://www.api.playit.mobi/api/v1/mondia/getCustomerId?msisdn=${phone}`;
  //     setloading(true);
  //     axios.get(url)
  //       .then(res => {
  //         if (res.data.status == true) {
  //           if (res.data.username && res.data.password) {
  //             const hasAccess = confirmLogin(res.data.username ,res.data.password)
  //             if (hasAccess) {
  //               history.push("/");
  //             }
  //           }
  //         }
  //         else {
  //           seterrors(false)
  //           seterrsub(true)
  //           seterrsubMsg(res.data.msg);
  //         }
  //         setloading(false)

  //       })
  //       .catch(function (error) {
  //        //console.log(error)
  //       })
  //       .finally(function () {
  //        //console.log('complete');
  //       });


  //   }
  // }

  async function redirectUrl(e) {
   ////console.log("task")
    e.preventDefault();
    window.location.href="https://gateway.mondiapay.com/v1/api/oauth/authorize?response_type=code&client_id=bd63dc95-ad08-4635-bbf9-a45f0dd8c071&redirect_uri=http://dzo.playit.mobi/login"

      // var url = "https://gateway.mondiapay.com/v1/api/oauth/authorize?response_type=code&client_id=bd63dc95-ad08-4635-bbf9-a45f0dd8c071&redirect_uri=http://dzo.playit.mobi";
      // setloading(true);
      // axios.get(url)
      //   .then(res => {

      //     if (res.data.status == true) {
      //       if (res.data.username && res.data.password) {
      //         const hasAccess = confirmLogin(res.data.username ,res.data.password)
      //         if (hasAccess) {
      //           history.push("/");
      //         }
      //       }
      //     }
      //     else {
      //       seterrors(false)
      //       seterrsub(true)
      //       seterrsubMsg(res.data.msg);
      //     }
      //     setloading(false)

      //   })
      //   .catch(function (error) {
      //    //console.log(error)
      //   })
      //   .finally(function () {
      //    //console.log('complete');
      //   });


   
  }

  async function checkAuth() {

    const auth = await client.auth();
    return auth;
  }

  function isValid(phone) {
    return phone ? true : false;
  }


  async function confirmLogin(username,password) {

    const hasAccess = await client.login(username,password);
    return hasAccess;
  }


  function onInputChange(value) {
    setphone(value);

  }

  return (
    <>
      {loading ?
        <div>
          <div className="spinerC">
            <div className="spinner"></div>
          </div>
        </div>
        : ""}
      <AlgeriaorLoginPageDisplay className="" bgImg={randomBg}>

        <div className="formPage">
          <form
          // onSubmit={e => {
          //   login(e);
          // }}
          >
            <div className="formsLayout" style={{ minHeight: '45vh' }}>
              <div className="header">
                <Link to="/">
                  <div className="logo" />
                </Link>
              </div>
              <div className="smsText" style={show ? { display: 'none' } : {}}>
                <p >يجب عليك الدخول عن طريق الرابط الموجود في SMS </p>
              </div>
              {/* <div className="center" style={{ width: '90%' }}>

                <div className="form">
                  <div className="iconInput">
                    <i className="icon phone" />
                    <input
                      type="number"
                      className="input"
                      autoComplete="phone"
                      placeholder={t("enter_phone_number")}
                      onChange={e =>
                        onInputChange(e.target.value)
                      }
                    />
                  </div>

                </div>
              </div> */}

            </div>
          </form>


          <div className="footer">
            <div className="normBtn">
              <button className="btn" onClick={e => redirectUrl(e)} disabled={validcode ? "disabled" : ""}>
                <Trans>sign_in</Trans>
              </button>
            </div>



            <p>
              <Trans>login.don’t_have_account</Trans>{" "}
              <Link to="signup">
                <Trans>sign_up</Trans>
              </Link>
            </p>
          </div>
          {errors ? <FloatingAlert type="error"><Trans>{errorMsg}</Trans></FloatingAlert> : ""}
          {errsub ? <FloatingAlert type="error"><Trans>{errsubMsg}</Trans></FloatingAlert> : ""}


        </div>
      </AlgeriaorLoginPageDisplay>
    </>
  );

}




/* End Update State Use REDUX */
export default withTranslation()(AlgeriaorLoginPage)



