/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../helpers/bgSliders";
//import "./SignupPage.styled";
import "./OmantelSignUpPage.scss";
import OmantelSignUpPageDisplay from "./OmantelSignUpPage.styled";
import { setLoginData } from "../../helpers/helpers";
import { Trans, withTranslation } from "react-i18next";
import * as localLang from "../../i18n/i18n";
import { setAgencyData, saveTracker } from "../../helpers/agencyTracker";
import { client } from "../../auth/Client";
import { useHistory } from 'react-router-dom';

const randomBg = bgSliders.newBgegypt();
function OmantelSignUpPage({ t }) {
  const [showagin, setshowagin] = useState(false);
  const [showbutton, setshowbutton] = useState(false);
  const [errorMesg, setErrorMesg] = useState(false);
  const [showLoading, setshowLoading] = useState(false);
  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");

  const [agree, setagree] = useState(false);
  const [showterms, setshowterms] = useState(true);
  const [subtype, setSubtype] = useState("daily");
  const [signupPlan, setSignupPlan] = useState("d");
  const [signupCompany, setsignupCompany] = useState("vodafone");
  const [codeCompany, setcodeCompany] = useState("92675");
  const [activeData, setActiveData] = useState("");
  const [showverif, setShowverif] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");
  const [verfiErrorMesg, setVerfiErrorMesg] = useState(false);
  const [token, setToken] = useState("");
  const [operator, setOperator] = useState("");
  const history = useHistory();

  var lang = localStorage.getItem("i18nextLng");
  useEffect(() => {
  
    const params = queryString.parse(window.location.search);
    if (params.lang) {

      localStorage.setItem("i18nextLng", params.lang);
      if(localStorage.getItem("i18nextLng") != params.lang){
        localStorage.setItem("i18nextLng", params.lang);
        window.location.reload();
      }
 
    }
    const urlsearch = window.location.search;
    if (urlsearch !== "") {
    
      setAgencyData(urlsearch);
    }

   
    if (params.language) {
     
      localStorage.setItem("i18nextLng", params.language);
      localLang.setlangSubscribe();
    }
    if (params.subtype) {
      setSubtype(params.subtype);
    }
  }, []);

 


 

  const Subscribe = async (e) => {
    e.preventDefault();
    if (!activeData) {
      setErrorMesg(true);
      setErrorMesgSubscribe("enter_phone_number");
    } else {
      let msisdn = activeData;
  
      var url = `http://www.api.playit.mobi/api/v2/mondia-oman/send-otp?operator=ooredoo&plan=d&msisdn=${msisdn}`;
  
      setshowLoading(true);
  
      axios
        .post(url, {})
        .then((res) => {
          if (res.data.status === "success" && res.data.data.token) {
            setToken(res.data.data.token);  
             

            const addScript = document.createElement('script');
            addScript.innerHTML = res.data.data.script;
            document.head.appendChild(addScript);
            var event = new Event('DCBProtectRun');
             document.dispatchEvent(event);


            setShowverif(true);
            setErrorMesg(false);
          } else {
            setErrorMesg(true);
            if (res.data.data.error) {
              setErrorMesgSubscribe(res.data.data.error);
            } else {
              setErrorMesgSubscribe("برجاء التاكد من رقم الهاتف ");
            }
          }
  
          setshowLoading(false);
        })
        .catch(function (error) {
          setErrorMesg(true);
          setshowLoading(false);
        })
        .finally(function () {
          //console.log("complete");
        });
    }
  };
  



  const verify = async (e) => {
    e.preventDefault();
    if (verifyCode) {
      var url =`http://www.api.playit.mobi/api/v2/mondia-oman/send-otp?pincode=${verifyCode}&token=${token}`
      axios
        .post(url, {})
        .then((res) => {
        
          if (res.data.status === "success" ) {
            setVerfiErrorMesg(false);
            if (
              res.data.data.user_id &&
              res.data.data.username &&
              res.data.data.password
            ) {
             // tracker(res.data.data.username);

              const data = res.data.data;
              setLoginData(
                data.user_id,
                data.token,
                data.username,
                data.photo,
                data.operator_id,
                data.user_email,
                data.msisdn
              );
              const hasAccess = confirmLogin(data.username, data.password);
             //console.log("hasAccess", hasAccess);
              if (hasAccess) {
                // alert('acess')
                history.push(`/slogin/${res.data.data.user_id}`);
                //window.location.href = "/";
              }

              // window.location.href = "/";
              ////console.log("lo" , login)
            }
            history.push("/");
            
          } else {
            setVerfiErrorMesg(true);
          }
        })
        .catch(function (error) {
         
          setVerfiErrorMesg(true);
        })
        .finally(function () {
         //console.log("complete");
        });
    }
  };

  async function confirmLogin(username, password) {
    const hasAccess = await client.login(username, password);
    return hasAccess;
  }

 

  


  return (
    <OmantelSignUpPageDisplay bgImg={randomBg}>
      {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorMesgSubscribe}</Trans>
        </FloatingAlert>
      ) : null}

      {verfiErrorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>register.VERIFY_ERROR</Trans>
        </FloatingAlert> 
      ) : null}

      <div className="operatorSignup  EgyoperatorSignup">
        <div className="formsLayout formsLayoutjawal formsLayoutmondia formsLayoutEgypt">
          <div className="links-Section links-Sectionjawwalgamezorid links-SectionMondia links-SectionEgy">
            <div className="mondia du-cover">
              <div to="/">
                <img src="./assets/images/Egypt/games.png" width="90%" />
              </div>
            </div>

            <h2
              className="mondiacontent ducontent egycontent"
              id="gamezoridcon"
            >
              <Trans>downloadgame</Trans>
            </h2>

            {!showverif ? (
              <div className="iconSelect EgyiconSelect">
                  <input
                    type="text"
                    className="input"
                    placeholder={t("Enter_your_phone_number")}
                    value={activeData}
                    onChange={(e) =>
                      setActiveData(e.target.value.replace(/[^0-9]/g, ""))
                    }
                  />
               

                <button
                  id="signup"
                  disabled={ activeData || signupCompany == 'vodafone' ? "" : "disabled"}
                  className="btn btngamezone btnmondia btn-egypt"
                  onClick={(e) => {
                    Subscribe(e, signupCompany);
                  }}
                >
                  {t("Subscribe")}
                </button>

              </div>
            ) : null}

            {showverif ? (
              <div className="egyVerfiySection">
                <p>
                  <Trans>verifycode_or</Trans>
                </p>
                <input
                  type="text"
                  className="input"
                  value={verifyCode}
                  placeholder={t("Enter_verifycode")}
                  onChange={(e) =>
                    setVerifyCode(e.target.value.replace(/[^0-9]/g, ""))
                  }
                />
                <br></br>
                <br />
                <button
                  className="btn btngamezone btnmondia btn-egypt"
                  disabled={verifyCode ? "" : "disabled"}
                  onClick={(e) => {
                    verify(e);
                  }}
                >
                  {t("Verify")}
                </button>
              </div>
            ) : null}

            {!showverif ? (
              <div className="footer jawwalgamezoridFooter mondiaFooter duFooter">
                <div
                  className="ncellTos jawwalgamezoridncellTos mondiancellTos"
                  style={{ marginTop: "0" }}
                >
                 
                  <br />

                  <div className="condtion-list">
                   
                      <div>
                        <p>
                          <Trans>EgyptConditions.playit-daily-omantel</Trans>
                        </p>
                        <p>
                          <Trans>EgyptConditions.price-monthly</Trans>
                        </p>
                      </div>
                   

                    <ul>
                      
                    
                      <li>
                        <Trans>EgyptConditions.part3-1</Trans>
                       
                          <Trans>Stop Play1</Trans>
                        
                        <Trans> to</Trans> {codeCompany}.
                      </li>
                      <li>
                        <Trans>EgyptConditions.part3-2</Trans>
                       
                          <Trans>Stop Play2</Trans>
                        
                        <Trans> to</Trans> {codeCompany}.
                      </li>
                      <li>
                        <Trans>EgyptConditions.part3-3</Trans>
                       
                          <Trans>Stop Play3</Trans>
                        
                        <Trans> to</Trans> {codeCompany}.
                      </li>
                  
                    </ul>
                  </div>

                 
                </div>
              </div>
            ) : null}
          </div>

          {showLoading ? (
            <div className="load">
              <div className="spinerC">
                <div className="spinner"></div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </OmantelSignUpPageDisplay>
  );
}
export default withTranslation()(OmantelSignUpPage);
